body {
  margin: 0;
  font-family: 'Avenir Next';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #101418;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face{
  font-family: "Avenir Next";
  src: url("./fonts/AvenirNextLTPro-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face{
  font-family: "Avenir Next";
  src: url("./fonts/AvenirNextLTPro-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face{
  font-family: "Avenir Next";
  src: url("./fonts/AvenirNextLTPro-It.otf") format("opentype");
  font-style: italic;
}
